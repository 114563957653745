@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Expanded:wght@400;500;600;700;800;900&display=swap');


*{
  font-family: 'Encode Sans Expanded',
  sans-serif;
}

h1, h2, h3, h4{
  font-family: 'Encode Sans Expanded',sans-serif;
  color: white;
}

p{
  font-size: 18px;
  color: white;
}


.top__section .container,
.second__section .container,
.third__section .container,
.forth__section .container,
.fifth__section .container {
  justify-content: center;
  align-items: center;
  flex: 1;
  display: flex;
  height: 100%;
}

.fifth__section {
  height: 90% !important;
}


.forth__section .row{
  justify-content: space-between;
}

.forth__section img{
  border-radius: 50%;
}


/* Header */
.navbar{
  background-color: #000000b5;
}

header .btn-primary {
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #01BF71;
  color: black;
  border-color: #01BF71;
  font-family: 'Encode Sans Expanded',sans-serif;
}

header .btn-primary:hover {
  background-color: white !important;
  color: black;
}

.navbar-light .navbar-nav .nav-link{
  font-family: 'Encode Sans Expanded',
  sans-serif;
  font-size: 18px;
  color: white !important;
}

.navbar-brand{
  font-size: 28px !important;
  font-family: 'Encode Sans Expanded',sans-serif;
  color: white !important;
  font-weight: 700;
}

.navbar-light .navbar-nav .nav-link:focus{
  text-shadow: 0px 0px 6px #09fdf4 !important,
  0px 0px 12px #09fdf4 !important,
  0px 0px 24px #09fdf4 !important,
  0px 0px 48px #09fdf4 !important,
}

 .navbar-light .navbar-toggler-icon {
   background-image: url('./assets/menu-icon.png') !important;
 }
/* Top Section */
.top__section{
  background: url('./assets/big__image.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
 

.top__section h1{
  font-size: 56px;
  font-family: 'Encode Sans Expanded',
  sans-serif;
  font-weight: 700;
}

.top__section .sub__heading{
  font-size: 28px;
  color: white;
}

.top__section .btn-primary{
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #01BF71;
  color: black;
  border-color: #01BF71;
 font-family: 'Encode Sans Expanded',
 sans-serif;
}

.top__section .btn-primary:hover{
  background-color: white !important;
  color: black;
}


/* Footer */
footer{
  background-color: #101522;
  padding: 2%;
}

.footer{
  height: auto !important;
}

/* Second Section */
.second__section{
  background-color: rgb(0, 0, 0) !important;
}


.second__section h2{
  font-size: 48px !important;
  font-weight: 700;
}

.second__section .content{
  color: white;
  font-size: 20px;
}

.second__section .btn-primary {
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #01BF71;
  color: black;
  border-color: #01BF71;
  font-family: 'Encode Sans Expanded',sans-serif;
}

.second__section .btn-primary:hover {
  background-color: white !important;
  color: black;
}

/* Third Section */
.third__section{
  background-color: #F9F9F9;
}

.third__section h2 {
  font-size: 48px !important;
  color: black;
  font-weight: 700;
}

.third__section ul {
  color: black;
  font-size: 20px;
  margin-left: 0px !important;
}

.third__section ul li::marker {
  color: #01BF71;
  font-size: 32px;
}

.third__section .btn-primary {
  padding: 12px 30px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  background-color: #01BF71;
  color: black;
  border-color: #01BF71;
  font-family: 'Encode Sans Expanded', sans-serif;
}

.third__section .btn-primary:hover {
  background-color: white !important;
  color: black;
}

.third__section p{
  color: black;
  font-size: 20px;
  line-height: 2.5rem;
}

.third__section .col-md-7{
  padding-left: 5%;
}

/* Forth Section */
.forth__section,
.fifth__section {
  background-color: #010606;
}

.forth__section h2,
.fifth__section h2 {
  font-size: 48px !important;
  font-weight: 700;
  color: white;
}

.fifth__section .text{
  font-size: 30px;
  color: #01BF71;
  font-weight: 700;
}

.fifth__section ul{
  padding-left: 0px;
  list-style: none;
  margin-top: 1.5rem;
  margin-bottom: 0px;
}

.fifth__section ul li{
  color: white;
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 600px) {
 
  .navbar-brand{
    font-size: 24px !important;
  }

.navbar-light .navbar-nav .nav-link{
  font-size: 14px !important;
}

  .second__section .video div:first-child{
    margin: auto;
  }

  .second__section h2,
  .third__section h2,
  .forth__section h2,
  .fifth__section h2 {
    font-size: 36px !important;
  }

  .second__section .content{
    font-size: 18px;
  }

  .third__section .col-md-7{
    margin-top: 30px;
  }

  .third__section ul,
  .third__section p {
    font-size: 18px;
  }

  .top__section .container,
  .second__section .container,
  .third__section .container,
  .forth__section .container,
  .fifth__section .container{
    align-items: normal !important;
  }

  .second__section,
  .third__section,
  .forth__section,
  .fifth__section {
    height: auto !important;
    padding: 20% 0;
  }

  .forth__section img{
    margin-bottom: 1rem !important;
  }

  .forth__section .col-md-3{
    margin-bottom: 2rem;
  }

  .fifth__section .text{
    font-size: 20px;
  }

  .fifth__section ul li{
    font-size: 18px !important;
  }
}